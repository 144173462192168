<template>
  <div class="flex-shrink-0">
    <Button
      v-if="addReply"
      v-tooltip.bottom="i18n.$gettext('Reply')"
      class="p-button-raised mr-2"
      icon="fa fa-reply"
      @click="$emit('email:reply', { id: email.originalId, folderId: folderId, replyAll: false })"
    />
    <Button
      v-if="addReply"
      v-tooltip.bottom="i18n.$gettext('Reply all')"
      class="p-button-raised mr-2"
      icon="fa fa-reply-all"
      @click="$emit('email:reply', { id: email.originalId, folderId: folderId, replyAll: true })"
    />
    <Button
      v-if="addForward"
      v-tooltip.bottom="i18n.$gettext('Forward message')"
      class="p-button-raised mr-2"
      icon="fa fa-forward"
      @click="$emit('email:forward', { id: email.originalId, folderId: folderId })"
    />
    <Button
      v-if="addDownload"
      v-tooltip.bottom="i18n.$gettext('Download message')"
      class="p-button-raised mr-2"
      icon="fa fa-download"
      @click="downloadEmail"
    />
    <Button
      v-if="addPrint"
      v-tooltip.bottom="i18n.$gettext('Print message')"
      class="p-button-raised mr-2"
      icon="fa fa-print"
      @click="$emit('email:print', { id: email.originalId })"
    />
    <Button
      v-if="addToggleSeen && seen"
      v-tooltip.bottom="i18n.$gettext('Mark unread')"
      class="p-button-raised mr-2"
      icon="fa fa-eye-slash"
      @click="toggleSeenFlag"
    />
    <Button
      v-else-if="addToggleSeen"
      v-tooltip.bottom="i18n.$gettext('Mark read')"
      class="p-button-raised mr-2"
      icon="fa fa-eye"
      @click="toggleSeenFlag"
    />
    <Button
      v-if="addSwitchMode && (email && email.rawEmail !== null && mode === 'rich')"
      v-tooltip.bottom="i18n.$gettext('Switch to plaintext view')"
      class="p-button-raised mr-2"
      icon="cil-code"
      @click="$emit('email:show-plaintext', { id: email.originalId })"
    />
    <Button
      v-if="addSwitchMode && (email && email.rawEmail !== null && mode === 'source')"
      v-tooltip.bottom="i18n.$gettext('Switch to rich text view')"
      class="p-button-raised mr-2"
      icon="fa fa-align-left"
      @click="$emit('email:show-richtext', { id: email.originalId })"
    />
    <Button
      v-if="addDelete"
      v-tooltip.bottom="i18n.$gettext('Move to trash')"
      class="p-button-raised mr-2"
      icon="fa fa-trash"
      @click="deleteEmail"
    />
    <Button
      v-if="addShowConversation && !showingConversation"
      v-tooltip.bottom="i18n.$gettext('Show conversation')"
      class="p-button-raised mr-2"
      icon="cil-chat-square"
      :disabled="!hasConversation"
      @click="$emit('email:toggle-conversation')"
    />
    <Button
      v-else-if="addShowConversation"
      v-tooltip.bottom="i18n.$gettext('Hide conversation')"
      class="p-button-raised mr-2"
      icon="cil-chat-square"
      :disabled="!hasConversation"
      @click="$emit('email:toggle-conversation')"
    />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/entry/Email"
import {mailServiceApi} from "@/api/MailServiceApi"
import LoadingButton from "@/components/common/LoadingButton.vue"
import { ref } from "@vue/reactivity"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {rpcClient} from "@/api/WebsocketClient"
import MenuBar from "@/components/common/MenuBar.vue"
import Button from "primevue/button"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import RpcError from "@/api/RpcError"

@Options({
  components: {
    //@ts-ignore
    LoadingButton, MenuBar, Button
  },
  //@ts-ignore
  props: {
    folderId: String,
    email: [ Email, Object ],
    addReply: {type: Boolean, default: false},
    addForward: {type: Boolean, default: false},
    addDownload: {type: Boolean, default: false},
    addToggleSeen: {type: Boolean, default: false},
    addMove: {type: Boolean, default: false},
    addDelete: {type: Boolean, default: false},
    addPrint: {type: Boolean, default: false},
    addSwitchMode: {type: Boolean, default: false},
    addShowConversation: {type: Boolean, default: false},
    hasConversation: {type: Boolean, default: false},
    showingConversation: {type: Boolean, default: false}
  },
  emits: [
    'email:reply',
    'email:forward',
    'email:move',
    'email:deleted',
    'email:print',
    'email:show-plaintext',
    'email:show-richtext',
    'email:toggle-conversation'
  ]
})
export default class DetailButtons extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  //@ts-ignore
  buttons: HTMLElement = ref<HTMLElement | null>(null)

  folderId!: string
  email!: Email

  addReply: boolean = false
  addForward: boolean = false
  addDownload: boolean = false
  addToggleSeen: boolean = false
  addMove: boolean = false
  addDelete: boolean = false
  addPrint: boolean = false
  addSwitchMode: boolean = false
  addShowConversation: boolean = false

  mode = 'rich'
  hasConversation: boolean = false
  showingConversation: boolean = false
  deleteComment = ''

  toggleSeenFlag() {
    if (this.email.originalId) {
      mailServiceApi._updateFlags(this.email.originalId, {SEEN: !this.seen}, {}).then(() => {
        this.toast.success(this.i18n.$gettext("Email flagged"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not update flag"))
      })
    }
  }

  downloadEmail() {
    if (this.email && this.email.originalId) {
      window.open('/groupware-api/v1/emails/' + encodeURIComponent(this.email.originalId), '_blank')
    }
  }

  permaLink() {
    if (this.email && this.email.originalId) {
      const el = document.createElement('textarea')
      el.value = window.location.protocol + '//' + window.location.host + '/' + this.email.originalId
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toast.success(this.i18n.$gettext('Link copied to clipboard.'))
    }
  }

  deleteEmail(): void {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this email?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (this.email?.originalId) {
          this.$emit('email:deleted', {id: this.email.originalId})
          return mailServiceApi._deleteMail(this.email.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Email deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Email could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  get seen(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('SEEN') >= 0
    } else {
      return false
    }
  }

  get flagged(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('FLAGGED') >= 0
    } else {
      return false
    }
  }

  get width() {
    return window.innerWidth
  }

  get isAudit() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('AUDIT') >= 0)
  }
}
</script>

<style scoped lang="scss">
.button-bar {
  height: 80px
}
.email-view {
  height: calc(100% - 200px)
}
@media screen and (min-width: 1200px) {
  .button-bar {
    height: 80px
  }
  .email-view {
    height: calc(100% - 80px)
  }
}
@media screen and (min-width: 1900px) {
  .button-bar {
    height: 86px
  }
  .email-view {
    height: calc(100% - 86px)
  }
}
</style>
