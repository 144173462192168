import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import INode from '@/model/entry/INode';
import InternalShareLink from '@/model/common/InternalShareLink';
import UploadLink from '@/model/common/UploadLink';
import Page from '@/model/Page';
import Permission from '@/model/entry/Permission';
import ShareLibraryStatus from '@/model/common/ShareLibraryStatus';
import Query from '@/model/common/Query';
import INodeHistory from '@/model/common/INodeHistory';
import { iNodeStore } from '@/store/INodeStore';

export default class GeneratedFileServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _createDirectory(path: string, password: string | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createDirectory', rpcParams, false).then((data: any) => {
            const model = Object.assign(new INode(), data)
            iNodeStore.addOrReplaceINode(model)
            return model.path
        })
    }

    _createFile(path: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createFile', rpcParams, false).then((data: any) => {
            const model = Object.assign(new INode(), data)
            iNodeStore.addOrReplaceINode(model)
            return model.path
        })
    }

    _deleteINode(path: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteINode', rpcParams, false).then(() => {
            iNodeStore.removeINode(path)
        })
    }

    _restoreFile(filePath: string, historyId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('restoreFile', rpcParams, false) as Promise<void>
    }

    _revertDirectory(path: string, historyId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('revertDirectory', rpcParams, false) as Promise<void>
    }

    _getUploadLink(path: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUploadLink', rpcParams, false) as Promise<string>
    }

    _getUpdateLink(path: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUpdateLink', rpcParams, false) as Promise<string>
    }

    _getDownloadLink(path: string, reuse: boolean): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getDownloadLink', rpcParams, false) as Promise<string>
    }

    _getInternalShareLink(path: string): Promise<InternalShareLink> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getInternalShareLink', rpcParams, false).then((data: any) => {
            return Object.assign(new InternalShareLink(), data)
        })
    }

    _createUploadLink(path: string, password: string | null, expireTime: string | null): Promise<UploadLink> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createUploadLink', rpcParams, false).then((data: any) => {
            return Object.assign(new UploadLink(), data)
        })
    }

    _listUploadLinks(path: string): Promise<UploadLink[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('listUploadLinks', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new UploadLink(), it)) : Promise.reject()
        })
    }

    _listSharedUsersOfINode(path: string): Promise<Permission[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('listSharedUsersOfINode', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new Permission(), it)) : Promise.reject()
        })
    }

    _listSharedGroupsOfINode(path: string): Promise<Permission[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('listSharedGroupsOfINode', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new Permission(), it)) : Promise.reject()
        })
    }

    _shareINodeToUsers(path: string, userIds: string[], permission: string | null): Promise<ShareLibraryStatus> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('shareINodeToUsers', rpcParams, false).then((data: any) => {
            return Object.assign(new ShareLibraryStatus(), data)
        })
    }

    _shareINodeToGroups(path: string, groupNames: string[], permission: string | null): Promise<ShareLibraryStatus> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('shareINodeToGroups', rpcParams, false).then((data: any) => {
            return Object.assign(new ShareLibraryStatus(), data)
        })
    }

    _unshareINodeFromUser(path: string, userId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('unshareINodeFromUser', rpcParams, false) as Promise<void>
    }

    _unshareINodeFromGroups(path: string, groupNames: string[]): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('unshareINodeFromGroups', rpcParams, false) as Promise<void>
    }

    _deleteINodeSharedToMe(path: string, from: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteINodeSharedToMe', rpcParams, false) as Promise<void>
    }

    _emptyTrash(arg0: string | null): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('emptyTrash', rpcParams, false) as Promise<void>
    }

    _deleteUploadLink(token: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteUploadLink', rpcParams, false) as Promise<void>
    }

    _getSharesByProject(projectId: string): Promise<INode[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getSharesByProject', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const iNodes: INode[] = data.map(iNode => Object.assign(new INode(), iNode))
                iNodeStore.addOrReplaceINodes(iNodes)
                return iNodes
            } else return Promise.reject()
        })
    }


    _getINodesByPath(parentPath: string): Promise<INode[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getINodesByPath', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const iNodes: INode[] = data.map(iNode => Object.assign(new INode(), iNode))
                iNodeStore.addOrReplaceINodes(iNodes)
                return iNodes
            } else return Promise.reject()
        })
    }


    _queryINodes(query: Query): Promise<INode[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('queryINodes', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const iNodes: INode[] = data.map(iNode => Object.assign(new INode(), iNode))
                iNodeStore.addOrReplaceINodes(iNodes)
                return iNodes
            } else return Promise.reject()
        })
    }


    _decryptDirectory(path: string, password: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('decryptDirectory', rpcParams, false) as Promise<void>
    }

    _renameINode(path: string, newName: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('renameINode', rpcParams, false).then((data: any) => {
            const model = Object.assign(new INode(), data)
            iNodeStore.removeINode(path)
            iNodeStore.addOrReplaceINode(model)
            return model.path
        })
    }

    _moveINode(path: string, destPath: string, overwrite: boolean): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('moveINode', rpcParams, false).then((data: any) => {
            const model = Object.assign(new INode(), data)
            iNodeStore.removeINode(path)
            iNodeStore.addOrReplaceINode(model)
            return model.path
        })
    }

    _copyINode(path: string, destPath: string, overwrite: boolean): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('copyINode', rpcParams, false).then((data: any) => {
            const model = Object.assign(new INode(), data)
            iNodeStore.addOrReplaceINode(model)
            return model.path
        })
    }

    _copyINodes(srcPath: string, files: string[], destDir: string, overwrite: boolean): Promise<INode[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('copyINodes', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const iNodes: INode[] = data.map(iNode => Object.assign(new INode(), iNode))
                iNodeStore.addOrReplaceINodes(iNodes)
                return iNodes
            } else return Promise.reject()
        })
    }


    _listINodeHistory(path: string): Promise<INodeHistory> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('listINodeHistory', rpcParams, false).then((data: any) => {
            return Object.assign(new INodeHistory(), data)
        })
    }

    _getDownloadLinkFromHistory(path: string, historyId: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getDownloadLinkFromHistory', rpcParams, false) as Promise<string>
    }

    _updateSharedINodeToUserPermission(path: string, userId: string, permission: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateSharedINodeToUserPermission', rpcParams, false) as Promise<void>
    }

    _updateSharedINodeToGroupsPermission(path: string, groupNames: string[], permission: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateSharedINodeToGroupsPermission', rpcParams, false) as Promise<void>
    }

    getINodesByPath(parentPath: string, refresh?: boolean | number, sortBy?: string[] | string): SWR<INode[], string[]> {
        //@ts-ignore
        const result: SWR<INode[], string[]> = reactive(new SWR<INode[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getINodesByPath' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getINodesByPath[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const iNodes: INode[] = []
                for (const id of data) {
                    const iNode: INode | undefined = iNodeStore.state.iNodes.get(id)
                    if (iNode) {
                        iNodes.push(iNode)
                    }
                }
                result.data = iNodes
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getINodesByPath(parentPath).then((data: INode[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(iNode => iNode.path || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let iNodes: INode[] = [...iNodeStore.state.iNodes.values()]
        if (parentPath) iNodes = SortAndFilterUtil.filter(iNodes, { parentPath: parentPath })
        SortAndFilterUtil.sort(iNodes, sortBy)
        
        result.data = iNodes
        return result
    }

    queryINodes(query: Query, refresh?: boolean | number): SWR<INode[], string[]> {
        //@ts-ignore
        const result: SWR<INode[], string[]> = reactive(new SWR<INode[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_queryINodes' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_queryINodes[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const iNodes: INode[] = []
                for (const id of data) {
                    const iNode: INode | undefined = iNodeStore.state.iNodes.get(id)
                    if (iNode) {
                        iNodes.push(iNode)
                    }
                }
                result.data = iNodes
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._queryINodes(query).then((data: INode[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(iNode => iNode.path || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const iNodes: INode[] = []
            for (const id of cached.data) {
                const iNode: INode | undefined = iNodeStore.state.iNodes.get(id)
                if (iNode) {
                    iNodes.push(iNode)
                }
            }
            result.data = iNodes
        }
        return result
    }

    getINodesFilterByParentPath(parentPath: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number, refresh?: boolean | number): SWR<INode[], string[]> {
        //@ts-ignore
        const result: SWR<INode[], string[]> = reactive(new SWR<INode[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getINodesByPath' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getINodesByPath[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const iNodes: INode[] = []
                for (const id of data) {
                    const iNode: INode | undefined = iNodeStore.state.iNodes.get(id)
                    if (iNode) {
                        iNodes.push(iNode)
                    }
                }
                result.data = iNodes
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getINodesByPath(parentPath).then((data: INode[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(iNode => iNode.path || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let iNodes: INode[] = [...iNodeStore.state.iNodes.values()]
        iNodes = SortAndFilterUtil.filter(iNodes, { parentPath: parentPath })
        SortAndFilterUtil.sort(iNodes, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            iNodes = iNodes.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        result.data = iNodes
        return result
    }

    getINodesFilterByQueryId(queryId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): INode[] {
        let iNodes: INode[] = [...iNodeStore.state.iNodes.values()]
        iNodes = SortAndFilterUtil.filter(iNodes, { queryId: queryId })
        SortAndFilterUtil.sort(iNodes, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            iNodes = iNodes.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return iNodes
    }

    getINodes(sortBy?: string[] | string, pageIndex?: number, pageSize?: number): INode[] {
        let iNodes: INode[] = [...iNodeStore.state.iNodes.values()]
        
        SortAndFilterUtil.sort(iNodes, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            iNodes = iNodes.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return iNodes
    }

    getINode(path: string): INode | undefined {
        return iNodeStore.state.iNodes.get(path)
    }
}
