export default class DataSource {
  scopes!: string[] | null;
  name!: string | null;
  displayName!: string | null;
  authenticationType!: string | null;
  callbackBaseUrl!: string | null;
  callbackParamName!: string | null;
  tokenFromCode!: string | null;
  EXECUTOR!: any | null;
  canceledJobIds!: number[] | null;
  icons!: string[] | null;

  constructor() {
    this.scopes = null;
    this.name = null;
    this.displayName = null;
    this.authenticationType = null;
    this.callbackBaseUrl = null;
    this.callbackParamName = null;
    this.tokenFromCode = null;
    this.EXECUTOR = null;
    this.canceledJobIds = null;
    this.icons = null;
  }
}
