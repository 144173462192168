
import {Options, Vue} from 'vue-class-component'
import {mailServiceApi} from "@/api/MailServiceApi"
import SWR from "@/api/SWR"
import EmailListItem from "@/components/email/EmailListItem.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import MenuBar from "@/components/common/MenuBar.vue"
import EmailComposer from "@/components/email/EmailComposer.vue"
import { ref } from "@vue/reactivity"
import EmailUtil from "@/util/EmailUtil"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Email from "@/model/entry/Email"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import MultiSelect from "primevue/multiselect"
import Dropdown from "primevue/dropdown"
import {mailFolderStore} from "@/store/MailFolderStore"
import FocusListener from "@/util/focusUtil"
import demoService from "@/util/demoService"
import Page from "@/model/Page"
import Query from "@/model/common/Query"
import TaskCreator from "@/components/common/TaskCreator.vue"
import Checkbox from "primevue/checkbox"
import Button from "primevue/button"
import {toEmail, toEmailFolder} from "@/router"
import {useConfirm} from "primevue/useconfirm"
import Dialog from "primevue/dialog"
import TreeSelect from "primevue/treeselect"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  components: {
    //@ts-ignore
    InfiniteList, Dropdown, EmailListItem, MenuBar, EmailComposer, MultiSelect, TaskCreator, Checkbox, Button,
    Dialog, TreeSelect
  },
  //@ts-ignore
  props: {
    folderId: String,
    emailId: String,
    searchQuery: {
      type: [ Query, Object ],
      default: null
    }
  },
  emits: ['compose:email']
})
export default class EmailList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  folderId!: string
  emailId!: string
  searchQuery!: Query | null
  showTaskCreatorDialog: boolean = false
  emailToCreateTaskFrom: Email|null = null

  selectedRootFolder: any = null
  showMoveModal: boolean = false

  selectedEmails: string[] = []

  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)

  focusListener: FocusListener = new FocusListener(() => {
    if (this.folderId) {
      mailServiceApi.getEmailPreviews(this.folderId, this.sortBy.value, this.systemFlagsContainsOne, null, 0, 100, 30000)
    }
  })

  pageSize = 50
  sortOptions: { value: string, label: string, icon: string, searchValue: string }[] = [
    { value: 'receivedDate:desc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-down', searchValue: 'ctime:desc' },
    { value: 'receivedDate:asc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-up', searchValue: 'ctime:asc' },
    { value: 'from:asc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-up', searchValue: 'meta:from:asc' },
    { value: 'from:desc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-down', searchValue: 'meta:from:desc' }
  ]
  sortBy: { value: string, label: string, icon: string, searchValue: string } = this.sortOptions[0]

  filterOptions: { value: string, label: string, icon: string }[] = [
    { value: 'flagged', label: this.i18n.$gettext('Flagged'), icon: '' },
    { value: 'unread', label: this.i18n.$gettext('Unread'), icon: '' },
  ]
  filterBy: { value: string, label: string, icon: string }[] = []

  setSortBy(event: any): void {
    if (event?.item?.value) {
      this.sortBy = event.item.value
    }
  }

  get moveToOptions(): { key: string, type: string, label: string, icon: string }[] {
    const folders: MailFolder[] = mailFolderServiceApi.getFolders(120000).data || []
    const folderTree = folders.map(folder => this.treeFromFolder(folder)) || []
    console.log(folderTree)
    return folderTree.sort((a: { key: string, type: string, label: string, icon: string },b: { key: string, type: string, label: string, icon: string }) => {
      return EmailUtil.compareType(a.type, b.type)
    })
  }

  treeFromFolder(folder: MailFolder): { key: string, type: string, label: string, icon: string } {
    const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(folder, this.i18n)
    const item: any = {
      key: folder.originalId,
      type: folder.type || folder.name?.toLowerCase(),
      label: mapping?.name || folder.name,
      icon: mapping?.icon || 'cil-inbox',

    }

    if (folder.subFolders && folder.subFolders.length > 0) {
      item['children'] = folder.subFolders.map(subFolder => this.treeFromFolder(subFolder))
    }

    return item
  }

  get sortLabel(): string {
    const sortBy = this.sortBy
    const option: any = this.sortOptions.find(option => option.value === sortBy.value)
    return option ? option.label : ''
  }

  get total(): number | null {
    return this.folderId ? Math.max(mailServiceApi.state.total || 0, this.allItems.length) : this.allItems.length
  }

  get allItems(): Email[] {
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    let emails: Email[] = mailServiceApi.getEmailsFilterByOriginalParentId(this.folderId, sortBy.value)
    let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
    if (this.filterBy.find(f => f.value.includes('flagged'))) {
      emails = SortAndFilterUtil.containsOne(emails, { systemFlags: systemFlagsContainsOne })
    }
    return emails
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], Page<string>>) | null {
    const folderId = this.folderId
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    if (this.searchQuery) {
      const searchQuery = this.searchQuery
      return (pageIndex: number, pageSize: number) => {
        //Short refresh threshold because otherwise changing sort order does not always work => needs investigation!
        return mailServiceApi.queryEmails(searchQuery, pageIndex, pageSize, sortBy.searchValue, 1000)
      }
    } else if (folderId) {
      let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], Page<string>> = mailServiceApi.getEmailPreviews(folderId, sortBy.value, systemFlagsContainsOne, null, pageIndex, pageSize, 120000)
        if (swr.call?.promise) swr.call?.promise.then((page: Page<string>) => {
          if (typeof page.total === 'number') {
            mailServiceApi.state.total = page.total
            return page.total
          } else {
            return page.hasMore
          }
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext('Failed to load results.'))
        })
        return swr
      }
    } else {
      return null
    }
  }

  get systemFlagsContainsOne(): string[] | null {
    const filterBy = this.filterBy
    let systemFlagsContainsOne: string[] | null = null
    if (filterBy.find(f => f.value.includes('unread'))) {
      systemFlagsContainsOne = []
      systemFlagsContainsOne.push('UNSEEN')
    }
    if (filterBy.find(f => f.value.includes('flagged'))) {
      if (!systemFlagsContainsOne) {
        systemFlagsContainsOne = []
      }
      systemFlagsContainsOne.push('FLAGGED')
    }
    return systemFlagsContainsOne
  }

  get isSentFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Sent')
  }

  get isDraftFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Drafts')
  }

  get isOnMobile(): boolean {
    return breakpointUtil.isOnMobile()
  }

  setFlag(seen: boolean|null, flag: boolean|null): void {
    if (seen == null && flag == null) return
    if (!this.selectedEmails.length && !this.emailId) return
    const originalIds: string[] = this.selectedEmails.length ? this.selectedEmails : [this.emailId ]
    const systemFlags: {[k: string]: boolean} = {}
    if (seen != null) systemFlags.SEEN = seen
    if (flag != null) systemFlags.FLAGGED = flag
    mailServiceApi._updateFlagsFor(originalIds, systemFlags, {}).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to update flags"))
    })
  }

  selectEmail(email: Email): void {
    if (email && email.originalId && email.systemFlags && this.isDraftFolder) {
      mailServiceApi.getFullMail(email.originalId).then((fullMail: Email | null) => {
        if (fullMail) this.composer.show(fullMail, email.originalId, this.folderId, null, null)
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
      })
    } else if (email.originalId) {
      const originalId: string = email.originalId
      void mailServiceApi._updateFlags(originalId, {SEEN: true}, {}).finally(() => {
        void mailServiceApi.getFullMail(originalId).then((fullMail: Email | null) => {
          if (fullMail && !fullMail.systemFlags) fullMail.systemFlags = [ 'SEEN' ]
          else if (fullMail && !fullMail.systemFlags?.includes('SEEN')) fullMail.systemFlags?.push('SEEN')
        })
      })
      toEmail(this.folderId, email.originalId)
    }
  }

  flagMessage(event: {id: string, folderId: string, systemFlags: any}) {
    mailServiceApi._updateFlags(event.id, event.systemFlags, {}).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not update flag"))
    })
  }

  deleteEmail(event: {id: string, folderId: string} | null) {
    if (this.selectedEmails.length || (this.emailId && !event)) {
      const count = this.selectedEmails.length || 1
      if (count > 1) {
        const translated = this.i18n.$ngettext("Do you want to delete this email?", "Do you want to delete %{ n } emails?", count)
        this.confirm.require({
          message: this.i18n.interpolate(translated, {'n': count}),
          header: this.i18n.$gettext("Confirmation"),
          icon: 'cil-warning',
          accept: () => {
            if (this.selectedEmails.length) {
              this.deleteMessages()
            } else {
              this.deleteMessage({id: this.emailId, folderId: this.folderId})
            }
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        })
      } else {
        if (this.selectedEmails.length) {
          this.deleteMessages()
        } else {
          this.deleteMessage({id: this.emailId, folderId: this.folderId})
        }
      }
    } else if (event) {
      this.deleteMessage(event)
    }
  }

  deleteMessage(event: {id: string, folderId: string}) {
    mailServiceApi._deleteMail(event.id).then(() => {
      this.toast.success(this.i18n.$gettext("Email deleted"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete message"))
    })
    if (this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id) {
      toEmailFolder(this.folderId)
    } else {
      return null
    }
  }

  mailChecked(originalId: string) {
    this.selectedEmails.push(originalId)
  }
  mailUnchecked(originalId: string) {
    for (let i = 0; i < this.selectedEmails.length; i++) {
      const id: string = this.selectedEmails[i]
      if (originalId === id) {
        this.selectedEmails.splice(i, 1)
      }
    }
  }

  deleteMessages() {
    if (!this.selectedEmails) return
    let folderId: string = ""
    if (this.$route?.params?.hasOwnProperty("email") && this.selectedEmails.find((id) => id === this.$route.params["email"])) {
      folderId = this.folderId
    }
    mailServiceApi.deleteMails(this.selectedEmails).then(() => {
      this.toast.success(this.i18n.$gettext("Emails deleted"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete messages"))
    }).finally(() => {
      this.selectedEmails = []
    })
    if (folderId) {
      toEmailFolder(folderId)
    }
  }

  moveCheckedToFolder() {
    this.showMoveModal = false
    let originalId: string = ""
    for (const [key, value] of Object.entries(this.selectedRootFolder)) {
      if(value === true){
        originalId = key //use first match
        break
      }
    }
    this.selectedRootFolder = null
    console.log(originalId)
    if (!originalId) return
    this.moveToFolder({id: "", sourceFolderId: this.folderId, targetFolderId: originalId})
  }

  moveToFolder(event: {id: string, sourceFolderId: string, targetFolderId: string}) {
    let folderId: string = ""
    if (this.$route?.params?.hasOwnProperty("email")) {
      if ((this.selectedEmails.length && this.selectedEmails.find((id) => id === this.$route.params["email"]) ||
        (!this.selectedEmails.length && this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id))) {
        folderId = this.folderId
      }
    }
    const originalIds: string[] = this.selectedEmails.length ? this.selectedEmails : [ event.id ]
    mailServiceApi._move(originalIds, event.sourceFolderId, event.targetFolderId, false).then(() => {
      this.toast.success(this.i18n.$gettext("Email moved"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not move email"))
    }).finally(() => {
      this.selectedEmails = []
    })
    if (folderId) {
      toEmailFolder(folderId)
    }
  }

  showTaskCreator(email: Email) {
    this.emailToCreateTaskFrom = email
    this.showTaskCreatorDialog = true
  }

  replyMessage(event: {id: string, folderId: string, replyAll: boolean}): Promise<void> {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const reply: Email = EmailUtil.createReply(email, event.replyAll)
        this.composer.show(reply, null, event.folderId, event.id, null)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  forwardMessage(event: {id: string, folderId: string, replyAll: boolean}) {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const forward: Email = EmailUtil.createForward(email)
        this.composer.show(forward, null, event.folderId, null, event.id)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  unmounted() {
    demoService.setIntervalCallback(() => {})
    this.focusListener.remove()
  }
}
