import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import MailFolder from '@/model/directory/MailFolder';
import Page from '@/model/Page';
import Permission from '@/model/entry/Permission';
import { mailFolderStore } from '@/store/MailFolderStore';

export default class GeneratedMailFolderServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _move(originalId: string, targetId: string | null, copy: boolean | null): Promise<MailFolder[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('move', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailFolders: MailFolder[] = data.map(mailFolder => Object.assign(new MailFolder(), mailFolder))
                mailFolderStore.replaceMailFolders(mailFolders)
                return mailFolders
            } else return Promise.reject()
        })
    }


    _getFolders(): Promise<MailFolder[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getFolders', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailFolders: MailFolder[] = data.map(mailFolder => Object.assign(new MailFolder(), mailFolder))
                mailFolderStore.replaceMailFolders(mailFolders)
                return mailFolders
            } else return Promise.reject()
        })
    }


    _createFolder(folder: string, root: string | null): Promise<MailFolder[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createFolder', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailFolders: MailFolder[] = data.map(mailFolder => Object.assign(new MailFolder(), mailFolder))
                mailFolderStore.replaceMailFolders(mailFolders)
                return mailFolders
            } else return Promise.reject()
        })
    }


    _renameFolder(originalId: string, newName: string): Promise<MailFolder[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('renameFolder', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailFolders: MailFolder[] = data.map(mailFolder => Object.assign(new MailFolder(), mailFolder))
                mailFolderStore.replaceMailFolders(mailFolders)
                return mailFolders
            } else return Promise.reject()
        })
    }


    _changeFolderACL(originalId: string, permissions: Permission[], recursive: boolean): Promise<MailFolder[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('changeFolderACL', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailFolders: MailFolder[] = data.map(mailFolder => Object.assign(new MailFolder(), mailFolder))
                mailFolderStore.replaceMailFolders(mailFolders)
                return mailFolders
            } else return Promise.reject()
        })
    }


    _deleteFolder(originalId: string): Promise<MailFolder[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteFolder', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailFolders: MailFolder[] = data.map(mailFolder => Object.assign(new MailFolder(), mailFolder))
                mailFolderStore.replaceMailFolders(mailFolders)
                return mailFolders
            } else return Promise.reject()
        })
    }


    getFolders(refresh?: boolean | number): SWR<MailFolder[], string[]> {
        //@ts-ignore
        const result: SWR<MailFolder[], string[]> = reactive(new SWR<MailFolder[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getFolders' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getFolders[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const mailFolders: MailFolder[] = []
                for (const id of data) {
                    const mailFolder: MailFolder | undefined = mailFolderStore.state.mailFolders.get(id)
                    if (mailFolder) {
                        mailFolders.push(mailFolder)
                    }
                }
                result.data = mailFolders
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getFolders().then((data: MailFolder[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(mailFolder => mailFolder.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let mailFolders: MailFolder[] = [...mailFolderStore.state.mailFolders.values()]
        
        
        
        result.data = mailFolders
        return result
    }

    getMailFolder(originalId: string): MailFolder | undefined {
        return mailFolderStore.state.mailFolders.get(originalId)
    }
}
