
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/entry/Email"
import ContextMenu from "primevue/contextmenu"
import { ref } from "@vue/reactivity"
import MailFolder from "@/model/directory/MailFolder"
import Avatar from "@/components/common/Avatar.vue"
import Skeleton from 'primevue/skeleton'
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import Button from "primevue/button"
import TieredMenu from "primevue/tieredmenu"
import EmailUtil from "@/util/EmailUtil"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import dayjs from "@/util/dayjs"
import {emailStore} from "@/store/EmailStore"
import breakpointUtil from "@/util/BreakpointUtil"
import Checkbox from "primevue/checkbox"
import {Watch} from "vue-property-decorator"

@Options({
  //@ts-ignore
  props: {
    folderId: String,
    item: [Email, Object],
    selectedEmails: {type: Array, default: []},
    selected: {type: Boolean, default: false},
    separateRecipients: {type: Boolean, default: false},
    isSent: {type: Boolean, default: false}
  },
  components: {
    ContextMenu, Avatar, Skeleton, TieredMenu, Button, Checkbox
  },
  emits: [
    'email:checked',
    'email:unchecked',
    'email:reset-selection',
    'email:task',
    'email:reply',
    'email:forward',
    'email:flag',
    'email:mark-unread',
    'email:move',
    'email:delete'
  ]
})
export default class EmailListItem extends Vue {

  i18n: Language = useGettext()
  folderId!: string

  item!: Email
  selectedEmails!: string[]
  selected: boolean = false
  checked: boolean = false
  isSent!: boolean

  hovering: boolean = false

  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)
  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)

  menuItems: any[] = []

  get showCheckbox(): boolean {
    return Boolean(!this.isOnMobile && (this.checked || this.selected || this.selectedEmails.length || this.hovering))
  }

  menuItemFromFolder(folder: MailFolder): any {
    const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(folder, this.i18n)
    const item: any = {
      label: mapping?.name || folder.name,
      icon: mapping?.icon || 'cil-inbox',
      command: () => { this.$emit('email:move', { id: this.email.originalId, sourceFolderId: this.folderId, targetFolderId: folder.originalId }) }
    }

    if (folder.subFolders && folder.subFolders.length > 0) {
      item['items'] = folder.subFolders.map(subFolder => this.menuItemFromFolder(subFolder))
    }

    return item
  }

  get date(): Date | null {
    return (this.email && this.email.receivedDate) ? new Date(this.email.receivedDate) : null
  }

  get isOnMobile(): boolean {
    return breakpointUtil.isOnMobile()
  }

  get elementClass(): string[] {
    let result = []
    let backgroundClass: string
    if (this.selected) {
      backgroundClass = 'bg-light'
    } else {
      backgroundClass = 'bg-hover-light'
    }
    if (!this.seen) {
      backgroundClass += ' unread'
    }
    result.push(backgroundClass)
    if(breakpointUtil.isOnMobile()){
      result.push("flex-wrap")
    }
    return result
  }

  get seen(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('SEEN') >= 0
    } else {
      return false
    }
  }

  get flagged(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('FLAGGED') >= 0
    } else {
      return false
    }
  }

  get answered(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('ANSWERED') >= 0
    } else {
      return false
    }
  }

  get email(): Email {
    if (this.item.originalId && !this.item.textBody) {
      return emailStore.state.emails.get(this.item.originalId) || this.item
    } else {
      return this.item
    }
  }

  get receivedDate(): string {
    return this.email.receivedDate ? dayjs(this.email.receivedDate).format('LLL') : ''
  }

  @Watch('checked')
  reportChecked(): void {
    if (this.checked) {
      this.$emit('email:checked', this.email.originalId)
    } else {
      this.$emit('email:unchecked', this.email.originalId)
    }
  }

  onHover() {
    this.hovering = true
  }

  onLeave() {
    this.hovering = false
  }

  @Watch('selectedEmails')
  updateChecked(): void {
    if (this.selectedEmails?.length == 0) {
      this.checked = false
    }
  }

  showContextMenu(event: Event) {
    if (this.contextMenu) {
      if (!this.selectedEmails?.find((id) => id === this.email.originalId)) {
        this.$emit('email:reset-selection')
        this.checked = false
      }
      this.updateMenuItems()
      this.menu.hide()
      void this.$nextTick(() => {
        this.contextMenu.toggle(event)
      })
    }
  }

  showMenu(event: Event) {
    if (this.menu) {
      this.updateMenuItems()
      this.contextMenu.hide()
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  updateMenuItems() {
    const folders: MailFolder[] | null = mailFolderServiceApi.getFolders().data

    const items: any[] = []
    if (!this.checked || this.selectedEmails.length == 1) {
      items.push({
        label: this.i18n.$gettext('Create or Edit Task'),
        icon: 'cil-task',
        command: () => {this.$emit('email:task', this.email)}
      })
      items.push({
        label: this.i18n.$gettext('Reply'),
        icon: 'fa fa-reply',
        command: () => { this.$emit('email:reply', { id: this.email.originalId, folderId: this.folderId, replyAll: false }) }
      })
      items.push({
        label: this.i18n.$gettext('Reply All'),
        icon: 'fa fa-reply-all',
        command: () => { this.$emit('email:reply', { id: this.email.originalId, folderId: this.folderId, replyAll: true }) }
      })
      items.push({
        label: this.i18n.$gettext('Forward'),
        icon: 'fa fa-forward',
        command: () => { this.$emit('email:forward', { id: this.email.originalId, folderId: this.folderId }) }
      })

      if (this.flagged) {
        items.push({
          label: this.i18n.$gettext('Unflag'),
          icon: 'cil-flag',
          command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { FLAGGED: false } }) }
        })
      } else {
        items.push({
          label: this.i18n.$gettext('Flag'),
          icon: 'cil-flag',
          command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { FLAGGED: true } }) }
        })
      }

      if (this.seen) {
        items.push({
          label: this.i18n.$gettext('Mark unread'),
          icon: 'cil-eye-slash',
          command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { SEEN: false } }) }
        })
      } else {
        items.push({
          label: this.i18n.$gettext('Mark read'),
          icon: 'cil-eye',
          command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { SEEN: true } }) }
        })
      }
    }

    if (folders?.find(f => f.originalId === this.email.originalParentId)?.type === '\\Trash') {
      items.push( {
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => { this.$emit('email:delete', { id: this.email.originalId, folderId: this.folderId }) }
      })
    } else {
      items.push( {
        label: this.i18n.$gettext('Move to trash'),
        icon: 'cil-trash',
        command: () => { this.$emit('email:delete', { id: this.email.originalId, folderId: this.folderId }) }
      })
    }

    if (folders && folders.length > 0) {
      items.push({
        label: this.i18n.$gettext('Move to...'),
        icon: 'cil-folder-move',
        items: folders.map(folder => this.menuItemFromFolder(folder))
      })
    }

    this.menuItems = items
  }
}
